import React from 'react';
import PoiComponent from '@ctrip/online-flight-poi';
import { ubtDevTrace } from '@/src/sources/common/lightUbt';

import OutsideClickHandler from '../OutsideClickHandler';

import Lib from '../../../utils/lib';

export default class Poi extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			showPoi: false,
			showCityPicker: false,
			inputValue: props.cityText,
		};

		this.textInput = null;
	}

	componentDidUpdate(prevProps, prevState) {
		if (this.props.cityText !== prevProps.cityText) {
			this.setState({ inputValue: this.props.cityText });
		}

		if (this.props.focusSecond && !prevProps.focusSecond) {
			this.handleCityPicker(null);
			this.props.cancelFocusMtSecondDCity && this.props.cancelFocusMtSecondDCity();
		}
		if (this.props.focus && !prevProps.focus) {
			this.handleCityPicker(null);
		}

		let { onSetCityActive } = this.props;
		if (onSetCityActive) {
			if ((this.state.showPoi || this.state.showCityPicker) && !prevState.showPoi && !prevState.showCityPicker) {
				onSetCityActive(true);
			} else if (
				!this.state.showPoi &&
				!this.state.showCityPicker &&
				(prevState.showPoi || prevState.showCityPicker)
			) {
				onSetCityActive(false);
			}
		}
	}

	// 关闭poi浮层，给子组件调用
	closePoi = () => {
		this.setState({ showPoi: false });
	};

	// 点击输入框，打开城市选择浮层
	handleCityPicker = () => {
		this.textInput && this.textInput.select();

		if (this.state.showPoi) {
			this.setState({ showCityPicker: false, showPoi: true });
		} else {
			this.setState({ showCityPicker: true, showPoi: false });
		}

		this.props.onFocus();
	};

	// 关闭城市选择浮层，给子组件调用
	closeCityPicker = () => {
		this.setState({ showCityPicker: false });
	};

	// 点击后，改变输入框的值
	changeInput = (inputValue) => {
		this.setState({ inputValue });
	};

	// 处理输入框输入事件
	handlePoiInput = (e) => {
		this.setState({
			inputValue: e.target.value,
			showPoi: true,
			showCityPicker: false,
		});
		if (!e.target.value) {
			this.props.changePoi(this.props.name, null);

			this.setState({ showCityPicker: true, showPoi: false });

			if (this.props.isHotSearch || this.props.isDistrictSearch) {
				this.hotSearchProcess();
			}
		}
	};

	// 关闭浮层
	onOutsideClick = () => {
		this.setState({ showPoi: false, showCityPicker: false });
	};

	// 键盘按键事件
	onKeyDown = (e) => {
		const keyCode = e.keyCode;

		// 用于通知子组件按键事件
		this.poiComponent && this.poiComponent.handleKeyClick && this.poiComponent.handleKeyClick(keyCode);

		// ENTER
		if (keyCode === 13) {
			e.preventDefault();
		}

		// TAB
		if (keyCode == 9) {
			this.onOutsideClick();
		}
	};

	// 输入框中的鼠标点击事件
	onMouseDown = () => {
		const { mode, onMouseDown } = this.props;
		if (onMouseDown) {
			onMouseDown(mode);
		}
	};

	// 自动聚焦下一个输入框
	focusNextInput = (flag) => {
		this.props.handleEnter && this.props.handleEnter(this.textInput, flag);
	};

	// 错误信息
	getErrorAndWarning = () => {
		const { errorMsg, warnMsg } = this.props;
		let msg = errorMsg || warnMsg || '';
		return msg ? <div className={errorMsg ? 'form-error-msg' : 'form-warning-msg'}>{msg}</div> : null;
	};

	// @热门国家和精选地区 用于恢复原有搜索框的操作，显示校验及重置边框边距
	// 恢复条件包括：在到达城市poi选择城市、更换出发城市而目前所选到达国家该城市不支持（弹出校验），删除出发城市
	hotSearchProcess = (selectedCityCode, data) => {
		if (this.props.currentFlightWay !== 'MT') {
			if (this.props.arrivalCountry !== '') {
				// 从热门国家搜索状态恢复以后，第一次点击时清空到达国家数据以去除校验
				this.props.changeArrivalCountry && this.props.changeArrivalCountry('');
			}

			if (this.props.isDistrictSearch) {
				if (this.props.mode === 'A') {
					this.props.changeToDistrictSwitch(false);
					this.props.changeArrivalDistrict('');
				}
				// 出发地是机场对应的城市可以选也是可以选择的
				if (data && data.type && data.type == 'airport') {
					selectedCityCode = data.ParentCode;
				}
				if (
					!selectedCityCode ||
					(this.props.mode === 'D' &&
						this.props.hasHotDistrictOfTheDepartCityNow &&
						!this.props.hasHotDistrictOfTheDepartCityNow(selectedCityCode))
				) {
					this.props.changeToDistrictSwitch(false);
					this.props.clearPoiItem('owACity'); // 该方法可以将单程往返一同清除
					this.props.showWarn();
					this.props.onSelectDistrictError &&
						data &&
						data.text &&
						this.props.onSelectDistrictError(data.text);
				}
			}
		}
	};
	render() {
		const { showPoi, showCityPicker, inputValue } = this.state;
		const {
			currentFlightWay: flightWay,
			mode,
			priceMapSwitch,
			changePoi,
			name,
			prevACity,
			curCity,
			isShowDepartCityDefault,
			clickHotDistrict,
			departCityCode,
			selectorStyle,
		} = this.props;

		return (
			<OutsideClickHandler onOutsideClick={this.onOutsideClick} className={this.props.outsideClassName}>
				<div className="cflt-poi">
					<div>
						<input
							aria-label={this.props.label}
							ref={(input) => {
								this.textInput = input;
							}}
							name={this.props.name}
							type="text"
							className={this.props.inputClass || 'form-input-v2'}
							placeholder={this.props.label || ''}
							onChange={this.handlePoiInput}
							value={Lib.filterNumberInPoiText(this.state.inputValue) || ''}
							onFocus={this.handleCityPicker}
							onBlur={this.props.onBlur}
							onKeyDown={this.onKeyDown}
							onMouseDown={this.onMouseDown}
							autoComplete="off"
							u_key="poi_input"
							u_remark={`点击POI输入框[${this.props.name}]`}
						/>
						{this.props.addon}
					</div>
					<PoiComponent
						{...{
							ref: (ref) => {
								this.poiComponent = ref;
							},
							mode,
							flightWay,
							showPoi,
							showCityPicker,
							selectorStyle,
							inputValue,
							closePoi: this.closePoi,
							changeInput: this.changeInput,
							changeCity: (data) => {
								ubtDevTrace('city_picker_select', data);
								changePoi(name, data);
							},
							closeCityPicker: this.closeCityPicker,

							// 热门地区相关
							priceMapSwitch,
							departCityCode,
							clickHotDistrict,
							hotSearchProcess: this.hotSearchProcess,

							// 自动聚焦下一个输入框
							focusNextInput: this.focusNextInput,

							// 展示默认城市需要参数
							isShowDepartCityDefault,
							prevACity,
							curCity,
						}}
					></PoiComponent>
				</div>

				{this.getErrorAndWarning()}
			</OutsideClickHandler>
		);
	}
}
